import React, { useState } from 'react';
import { Link, useLocation } from '@reach/router';
import { Box } from '@material-ui/core';
import Menu from '../Menu';
import MenuDrawer from '../MenuDrawer';
import AuthMenu from '../../Auth/components/Menu';
import CommonLanguageSwitch from '../../Common/components/LanguageSwitch';
import { ReactComponent as Logo } from './logo-second.svg';
import { ReactComponent as MenuIcon } from './menu.svg';
import classes from './Navbar.module.css';
import CartButton from 'components/CartButton';
import VoucherCartButton from '../VoucherCartButton/VoucherCartButton';
import { shallowEqual, useSelector } from 'react-redux';
import AppState from 'interfaces/app-state';

export default function Navbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const { voucher } = useSelector(
    (state: AppState) => ({
      voucher: state.cart.voucher,
    }),
    shallowEqual
  );

  function handleOpen() {
    setDrawerOpen(true);
  }

  function handleClose() {
    setDrawerOpen(false);
  }

  function handleScroll() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <nav className={`navbar navbar-expand-sm ${classes.nav}`}>
      <div className="container">
        <div className={classes.container}>
          <button className={classes.menuButton} aria-label="Menu" onClick={handleOpen}>
            <MenuIcon className={classes.menuImage} title="Menu" />
          </button>
          <Link to="/" onClick={handleScroll}>
            <Logo className={classes.logoImage} title="Repair Rebels" width={200} height={29} />
          </Link>
          <div className="d-flex align-items-center">
            <div className="d-none d-lg-block mr-1 mr-xl-2">
              <Menu />
            </div>
            <Box ml=".5rem" mr=".5rem">
              <CommonLanguageSwitch />
            </Box>
            <Box>
              <AuthMenu />
            </Box>
            {voucher ? <VoucherCartButton location={location} /> : <CartButton location={location} />}
          </div>
        </div>
      </div>
      <MenuDrawer open={drawerOpen} onClose={handleClose} />
    </nav>
  );
}
