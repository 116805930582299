import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import banner1 from './banner1.png';
import banner2 from './banner2.png';
import banner3 from './banner3.png';
import banner4 from './banner4.png';
import banner5 from './banner5.png';
import banner7 from './banner7.png';
import banner8 from './banner8.png';
import mobileBanner1 from './mobile-assets/banner1.png';
import mobileBanner2 from './mobile-assets/banner2.png';
import mobileBanner3 from './mobile-assets/banner3.png';
import mobileBanner4 from './mobile-assets/banner4.png';
import mobileBanner5 from './mobile-assets/banner5.png';
import SwiperCore, { Mousewheel, Autoplay } from 'swiper';
import styles from './BannerSlider.module.css';
import { BannerSliderItem } from './BannerSliderItem';
import { useMediaQuery } from '@material-ui/core';
import { BannerSliderItemMobile } from './BannerSliderItemMobile';

const BannerSlider = () => {
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const images = [banner4, banner8, banner7, banner3, banner1];
  const mobileImages = [mobileBanner1, mobileBanner2, mobileBanner3, mobileBanner4, mobileBanner5];
  const bannerImages = isMobile ? mobileImages : images;

  SwiperCore.use([Mousewheel, Autoplay]);

  return (
    <Swiper
      loop
      slidesPerView={'auto'}
      pagination={{
        clickable: true,
      }}
      autoplay={{
        delay: 4000,
      }}
      spaceBetween={150}
      centeredSlides
      className={styles.swiper}
    >
      {bannerImages.map((src) => (
        <SwiperSlide key={src} style={{ backgroundImage: `url(${src})` }} className={isMobile ? styles.slideMobile : styles.slide}>
          {isMobile ? <BannerSliderItemMobile /> : <BannerSliderItem />}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default BannerSlider;
